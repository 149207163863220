
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TooltipBulkInstructionAutocomplete',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggleAutocomplete () {
      this.$emit('input', !this.value)
    }
  }
})
