
import { defineComponent, PropType } from 'vue'
import { InstructionDsp } from '../../../../../types/instruction_type'
import BulkOttoIo from '@/components/TableComponents/Form/BulkOtto/BulkOttoIo.vue'
import BulkInstructionAutocomplete from '@/components/TableComponents/Form/BulkCommon/BulkInstructionAutocomplete.vue'
import { getRightOptimizeField } from '../../../../../utils/instructionsUtils'
import BulkOttoVMenu from '@/components/TableComponents/Form/BulkOtto/BulkOttoVMenu.vue'
import TooltipBulkInstructionAutocomplete
  from '@/components/TableComponents/Form/BulkCommon/TooltipBulkInstructionAutocomplete.vue'

export type BulkToggleValue = boolean|'N/A'

export type BulkOttoParams = {
  otto: BulkToggleValue
  ottoMinViz: BulkToggleValue
  explodeTheInstructions: false
}

export default defineComponent({
  name: 'BulkOttoComponent',
  props: {
    ioList: {
      type: Array as PropType<Array<string>>,
      required: true
    },
    dsp: {
      type: String as PropType<InstructionDsp>,
      required: true
    }
  },
  components: {
    BulkOttoVMenu,
    BulkOttoIo,
    BulkInstructionAutocomplete,
    TooltipBulkInstructionAutocomplete
  },
  data: function () {
    return {
      bulkOttoParams: {
        otto: 'N/A',
        ottoMinViz: 'N/A',
        explodeTheInstructions: false
      } as BulkOttoParams,
      isProcessingExplode: false,
      isProcessingBulkOtto: false,
      isProcessingBulkOttoSimulate: false,
      errorsToDisplay: [],
      simulatedInstructions: [],
      ioFromAutocomplete: [],
      showAutocomplete: false,
      MAX_IO_TO_BULK: 80
    }
  },
  methods: {
    async  simulateExplodeTheInstructions () {
      this.isProcessingExplode = true
      this.simulatedInstructions = []
      const externalIds: string[] = this.fullIoList
      const optiAuto = this.bulkOttoParams.otto !== 'N/A' ? this.bulkOttoParams.otto : null
      const ottoMinViz = this.bulkOttoParams.ottoMinViz !== 'N/A' ? this.bulkOttoParams.ottoMinViz : null
      const subDsp = this.dsp
      const recency = 7 // day

      const response = await this.$apiCaller.postSimulateExplode(externalIds, subDsp, optiAuto, ottoMinViz, recency, true)

      if (this.$apiCaller.isResponseError(response)) {
        if (response.response.status !== 400) {
          this.$store.commit('setErrorMessageWithResponse', response)
        }
      } else {
        this.simulatedInstructions = response.data
      }
      this.isProcessingExplode = false
    },
    async bulkOtto () {
      this.isProcessingBulkOtto = true
      const externalIds: string[] = this.fullIoList
      const optiAuto = this.bulkOttoParams.otto !== 'N/A' ? this.bulkOttoParams.otto : null
      const ottoMinViz = this.bulkOttoParams.ottoMinViz !== 'N/A' ? this.bulkOttoParams.ottoMinViz : null
      const subDsp = this.dsp
      const explode = this.bulkOttoParams.explodeTheInstructions

      const response = await this.$apiCaller.postBulkOtto(externalIds, subDsp, optiAuto, ottoMinViz, explode)

      if (this.$apiCaller.isResponseError(response)) {
        this.$store.commit('setErrorMessageWithResponse', response)
      } else {
        const successMessage = 'Bulk otto successfully applied'
        this.$store.commit('setSuccessMessage', successMessage)
      }
      this.isProcessingBulkOtto = false
      this.emitSuccess()
    },
    async simulateBulkOtto () {
      this.isProcessingBulkOttoSimulate = true
      const externalIds: string[] = this.fullIoList
      const optiAuto = this.bulkOttoParams.otto !== 'N/A' ? this.bulkOttoParams.otto : null
      const ottoMinViz = this.bulkOttoParams.ottoMinViz !== 'N/A' ? this.bulkOttoParams.ottoMinViz : null
      const subDsp = this.dsp
      const explode = this.bulkOttoParams.explodeTheInstructions

      const response = await this.$apiCaller.postSimulateBulkOtto(externalIds, subDsp, optiAuto, ottoMinViz, explode, true)

      if (this.$apiCaller.isResponseError(response)) {
        if (response.response.status === 400) {
          const errors = response.response.data.errors
          if (Array.isArray(response.response.data.errors)) {
            this.errorsToDisplay = errors
          } else {
            this.errorsToDisplay = [errors]
          }
        } else {
          this.$store.commit('setErrorMessageWithResponse', response)
        }
      } else {
        this.errorsToDisplay = []
      }
      this.isProcessingBulkOttoSimulate = false
    },
    closeDialog () {
      this.$emit('close')
    },
    emitSuccess () {
      this.$emit('success')
    },
    isDisabledApply (): boolean {
      return this.nothingToApply || this.isProcessingBulkOtto || this.isProcessingBulkOttoSimulate || this.isProcessingExplode || this.errorsToDisplay.length > 0
    },
    addIoIdToBulk (ioId: string[]) {
      this.ioFromAutocomplete = [...this.ioFromAutocomplete, ...ioId]
    },
    toggleAutocomplete () {
      this.showAutocomplete = !this.showAutocomplete
    },
    removeIoFromList (ioId: string) {
      // if the ioId is in the autocomplete list, we remove it from the autocomplete list
      // otherwise, we emit a event to remove it from the bulkOttoParams

      // check is the ioId is in the autocomplete list
      if (this.ioFromAutocomplete.find((item) => item === ioId)) {
        this.ioFromAutocomplete = this.ioFromAutocomplete.filter((item) => item !== ioId)
        return
      }
      this.$emit('remove-io', ioId)
    },
    getKeysToDisplayExplode (): string[] {
      return [
        getRightOptimizeField(this.dsp),
        'objective',
        'opti_ratio'
      ]
    }
  },
  mounted () {
    this.bulkOttoParams = {
      otto: 'N/A',
      ottoMinViz: 'N/A',
      explodeTheInstructions: false
    }
  },
  computed: {
    getLabelIo (): string {
      return this.$dspConfig[this.$route.params.dsp as InstructionDsp].ioFieldLabel
    },
    allIsNA (): boolean {
      return this.bulkOttoParams.otto === 'N/A' && this.bulkOttoParams.ottoMinViz === 'N/A'
    },
    nothingToApply (): boolean {
      return this.allIsNA && !this.bulkOttoParams.explodeTheInstructions
    },
    displayErrorAlert (): boolean {
      return this.errorsToDisplay.length > 0
    },
    tooMuchIo (): boolean {
      return this.fullIoList.length > this.MAX_IO_TO_BULK
    },
    isSomethingProcessed (): boolean {
      return this.isProcessingBulkOtto || this.isProcessingBulkOttoSimulate || this.isProcessingExplode
    },
    isASimulationProcessing (): boolean {
      return this.isProcessingBulkOttoSimulate || this.isProcessingExplode
    },
    fullIoList (): string[] {
      return [...new Set([...this.ioList, ...this.ioFromAutocomplete])]
    }
  },
  watch: {
    bulkOttoParams: {
      handler: function (bulkOttoParams: BulkOttoParams) {
        if (!this.nothingToApply) {
          this.simulateBulkOtto()
        }

        if (!bulkOttoParams.explodeTheInstructions) {
          this.simulatedInstructions = []
        } else {
          this.simulateExplodeTheInstructions()
        }
      },
      deep: true
    },
    fullIoList: {
      handler: function () {
        if (!this.nothingToApply) {
          this.simulateBulkOtto()
        }
      }
    }
  }
})
